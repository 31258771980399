import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ziggurat-web/ziggurat-web/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "upgrade-guide-for-ziggurat-310"
    }}>{`Upgrade Guide for Ziggurat 3.1.0`}</h1>
    <hr></hr>
    <h2 {...{
      "id": "configuration-changes"
    }}>{`Configuration Changes`}</h2>
    <p>{`There were some breaking changes to kafka streams library being used by Ziggurat version 3.0.
Ziggurat 3.0 has upgraded kafka streams to v2.1. This requires the user to follow certain steps while
upgrading. These are explained below.`}</p>
    <p>{`For upgrading Ziggurat to 3.1.0, per `}<a parentName="p" {...{
        "href": "https://kafka.apache.org/21/documentation/streams/upgrade-guide"
      }}>{`Apache Kafka Upgrade Guide`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://cwiki.apache.org/confluence/display/KAFKA/KIP-268%3A+Simplify+Kafka+Streams+Rebalance+Metadata+Upgrade#KIP-268:SimplifyKafkaStreamsRebalanceMetadataUpgrade-Upgradingto2.0:"
      }}>{`KIP-268`}</a>{`, the user need to follow these steps`}</p>
    <ul>
      <li parentName="ul">{`Add a new config `}<inlineCode parentName="li">{`upgrade-from`}</inlineCode>{` for each of the top level config-map in :stream-router section of
config.edn. This config can be added either in `}<inlineCode parentName="li">{`config.edn`}</inlineCode>{` file in the project or as an environment
variable,
as explained below.`}</li>
      <li parentName="ul">{`Do a rolling deploy of the application with the newly added configuration (above).`}</li>
      <li parentName="ul">{`Remove the configs added above`}</li>
      <li parentName="ul">{`Do a rolling deploy of the application again.`}</li>
    </ul>
    <p>{`This can be understood with the help of an example. For the following stream-router configuration`}</p>
    <pre><code parentName="pre" {...{}}>{`:stream-router        {:topic-entity-1       {:application-id                 "application-1"
                                              :bootstrap-servers              "localhost:9092"
                                              :origin-topic                   "first-topic"}}
                      {:topic-entity-2       {:application-id                 "application-2"
                                              :bootstrap-servers              "localhost:9092"
                                              :origin-topic                   "second-topic"}}
`}</code></pre>
    <p>{`For projects using Ziggurat version <= 2.7.2, (`}<inlineCode parentName="p">{`[tech.gojek/ziggurat "2.6.0"]`}</inlineCode>{`, for example),
the new stream-router config should look like this`}</p>
    <pre><code parentName="pre" {...{}}>{`:stream-router        {:topic-entity-1       {:application-id                 "application-1"
                                              :bootstrap-servers              "localhost:9092"
                                              :origin-topic                   "first-topic"
                                              :upgrade-from                   "0.11.0"}}
                      {:topic-entity-2       {:application-id                 "application-2"
                                              :bootstrap-servers              "localhost:9092"
                                              :origin-topic                   "second-topic"
                                              :upgrade-from                   "0.11.0"}}
`}</code></pre>
    <p>{`Or, if the user is not comfortable with modifying `}<inlineCode parentName="p">{`config.edn`}</inlineCode>{`, equivalent environment variables can be added to
the project environment.`}</p>
    <pre><code parentName="pre" {...{}}>{`ZIGGURAT_STREAM_ROUTER_TOPIC_ENTITY_1_UPGRADE_FROM=0.11.0
ZIGGURAT_STREAM_ROUTER_TOPIC_ENTITY_2_UPGRADE_FROM=0.11.0
`}</code></pre>
    <p>{`For projects using Ziggurat version > 2.7.2, (`}<inlineCode parentName="p">{`[tech.gojek/ziggurat "2.12.1"]`}</inlineCode>{`, for example), the new stream-router config
should look like`}</p>
    <pre><code parentName="pre" {...{}}>{`:stream-router        {:topic-entity-1       {:application-id                 "application-1"
                                              :bootstrap-servers              "localhost:9092"
                                              :origin-topic                   "first-topic"
                                              :upgrade-from                   "1.1"}}
                      {:topic-entity-2       {:application-id                 "application-2"
                                              :bootstrap-servers              "localhost:9092"
                                              :origin-topic                   "second-topic"
                                              :upgrade-from                   "1.1"}}
`}</code></pre>
    <p>{`Or, an equivalent environment variable can be added for each of the entiteis in `}<inlineCode parentName="p">{`stream-router`}</inlineCode>{` section. Like this`}</p>
    <pre><code parentName="pre" {...{}}>{`ZIGGURAT_STREAM_ROUTER_TOPIC_ENTITY_1_UPGRADE_FROM=1.1
ZIGGURAT_STREAM_ROUTER_TOPIC_ENTITY_2_UPGRADE_FROM=1.1
`}</code></pre>
    <h2 {...{
      "id": "using-middlewares"
    }}>{`Using Middlewares`}</h2>
    <p>{`In the versions preceding 3.0, Ziggurat would only process messages which were serialized
in protobuf format. This was a major limitation as users could not use any other formats like JSON
or Avro. `}</p>
    <p>{`In Ziggurat 3.0, the logic for deserialization has been extracted out as middlewares
which can be used not only for deserializing a message in any given format, but
can be plugged together to perform a set of tasks before a message is processed.`}</p>
    <p>{`You can read more about them at `}<a parentName="p" {...{
        "href": "https://github.com/gojek/ziggurat#middleware-in-ziggurat"
      }}>{`Ziggurat Middlewares`}</a></p>
    <p>{`As far as message processing is concerned, messages will be provided as byte arrays and the user
has to explicitly use `}<inlineCode parentName="p">{`ziggurat.middleware.default/protobuf->hash`}</inlineCode>{` to deserialize a message
before processing it.`}</p>
    <p>{`For example, in previous versions, for this stream-routes configuration,`}</p>
    <pre><code parentName="pre" {...{}}>{`:stream-router        {:topic-entity-1       {:application-id                 "application-1"
                                              :bootstrap-servers              "localhost:9092"
                                              :origin-topic                   "first-topic"}}
                                              :channels {:geofence-channel {:worker-count [10 :int]
                                                         :retry            {:count [3 :int]}}}}
                      {:topic-entity-2       {:application-id                 "application-2"
                                              :bootstrap-servers              "localhost:9092"
                                              :origin-topic                   "second-topic"}}
                                                                                                                                                                             :enabled [true :bool]
`}</code></pre>
    <p>{`a mapper function in a Ziggurat-based project would look like this`}</p>
    <pre><code parentName="pre" {...{}}>{`(init/main start-fn stop-fn {:topic-entity-1 {:handler-function  mapper-fn 
                                              :geofence-channel  channel-mapper-fn}
                             topic-entity-2  {:handler-function  mapper-fn-2}})
`}</code></pre>
    <p>{`An upgrade to Ziggurat 3.0 would require the user to write a new mapper function
which explicitly deserializes the message using the proto middleware (provided in Ziggurat be default)
before passing it as an argument to the old mapper function.`}</p>
    <pre><code parentName="pre" {...{}}>{`(def middleware-based-mapper-fn
  (-> mapper-fn
      (ziggurat.middleware.default/protobuf->hash com.gojek.esb.booking.BookingLogMessage :topic-entity-1)))
      
(def middleware-based-mapper-fn-2
  (-> mapper-fn-2
      (ziggurat.middleware.default/protobuf->hash com.gojek.esb.booking.BookingLogMessage :topic-entity-2)))
      
(def middleware-based-channel-mapper-fn
  (-> channel-mapper-fn
      (ziggurat.middleware.default/protobuf->hash com.gojek.esb.booking.BookingLogMessage :topic-entity-1)))

(init/main start-fn stop-fn {:topic-entity-1 {:handler-function  middleware-based-mapper-fn 
                                              :geofence-channel  middleware-based-channel-mapper-fn}
                             topic-entity-2  {:handler-function  middleware-based-mapper-fn-2}})
`}</code></pre>
    <p>{`A similar change will be required for all the handler-functions/channel-functions in
`}<inlineCode parentName="p">{`stream-routes`}</inlineCode>{` map which is passed to `}<inlineCode parentName="p">{`init/main`}</inlineCode>{`.`}</p>
    <p>{`Development is under way to provide a JSON middleware for deserializing JSON messages.
It is expected to be available in 3.1.0-alpha.2.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      